import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import heart from "../../images/icon-wedding-heart-primary.svg"
import deco from "../../images/ornament-divider.png"
import story from "../../images/clip-path-love-story.png"

const styles = {
    paperContainer: {
        height: 1356,
        backgroundImage: `url(${"static/src/img/main.jpg"})`
    }
};

function Invitaion() {

    return (
        <>
            <Grid container justifyContent="center" style={{ marginTop: "25px" }}>
                <Grid item xl={12} xs={12} textAlign="center">
                    <img src={deco} style={{ height: "60px" }}></img>
                </Grid>
            </Grid>
            <Grid item xl={12} xs={12} textAlign="center">
                <h2 style={{fontFamily: "cursive"}}>
                    Engagement - నిశ్చితార్థం
                </h2>
            </Grid>

            <Grid container justifyContent="center" alignItems="center" style={{ marginTop: "25px" }}>
                <Grid item xl={4} xs={3} spacing={0} textAlign="center"></Grid>
                <Grid item xl={4} md={6} xs={12} spacing={0} textAlign="center">
                    <div style={{ position: "relative" }}>
                        <div style={{ position: "absolute", height: "100%", width: "100%" }}><img src={story} style={{ height: "100%", width: "100%" }} ></img></div>
                        <Card style={{ backgroundColor: "#FBF0F6" }}>
                            <CardContent>
                                <Grid container justifyContent="center" alignItems="center" >
                                    <Grid item xl={2} xs={2} spacing={0} textAlign="center">
                                        <svg fill="#6D8A91" height="50px" width="50px" version="1.1" id="Layer_1"
                                            viewBox="0 0 512 512" >
                                            <g>
                                                <g>
                                                    <g>
                                                        <path d="M416.278,34.758V7.928h-33.391v26.829h-62.33V7.928h-33.391v26.829h-62.33V7.928h-33.391v26.829h-62.33V7.928H95.722
				v26.829H0v469.316h512V34.758H416.278z M478.609,470.682H33.391v-294.89h445.217V470.682z M478.609,142.401H33.391V68.149h62.33
				V94.97h33.391V68.149h62.33V94.97h33.391V68.149h62.33V94.97h33.391V68.149h62.33V94.97h33.391V68.149h62.33V142.401z"/>
                                                        <path d="M214.239,390.9c13.457,10.408,31.892,22.392,32.671,22.898l9.09,5.9l9.09-5.9c0.778-0.505,19.214-12.491,32.671-22.898
				c30.47-23.566,65.444-55.368,65.444-92.425c-0.001-37.767-30.726-68.491-68.491-68.491c-13.929,0-27.41,4.262-38.714,12.007
				c-11.303-7.746-24.785-12.007-38.714-12.007c-37.766,0-68.491,30.724-68.491,68.491
				C148.795,335.532,183.769,367.334,214.239,390.9z M217.287,263.376c10.006,0,19.57,4.301,26.237,11.799L256,289.207
				l12.477-14.032c6.667-7.497,16.23-11.799,26.237-11.799c19.354,0,35.1,15.745,35.1,35.1
				c-0.001,16.868-17.657,39.079-52.481,66.012c-6.719,5.197-15.077,11.009-21.333,15.248c-6.25-4.235-14.602-10.043-21.331-15.248
				c-34.824-26.933-52.481-49.143-52.481-66.012C182.187,279.121,197.932,263.376,217.287,263.376z"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg></Grid>
                                    <Grid item xl={6} xs={10} spacing={0} textAlign="center"><h3>Friday - December 8th</h3></Grid>
                                </Grid>
                                <Grid container justifyContent="center" alignItems="center" >
                                    <Grid item xl={2} xs={2} spacing={0} textAlign="center">
                                        <svg fill="#6D8A91" height="50px" width="50px" version="1.1" id="Layer_1"
                                            viewBox="0 0 512 512" >
                                            <g>
                                                <g>
                                                    <g>
                                                        <circle cx="89.099" cy="222.253" r="19.887" />
                                                        <path d="M454.524,298.532c11.855-0.172,22.977-4.872,31.379-13.275c8.506-8.506,13.152-19.729,13.292-31.397
				c8.154-8.351,12.806-19.574,12.806-31.607c0-12.032-4.651-23.256-12.804-31.606c-0.141-11.668-4.787-22.892-13.293-31.399
				c-8.507-8.505-19.728-13.151-31.397-13.291c-6.179-6.032-13.933-10.141-22.421-11.874c-10.461-35.311-31.6-67.104-60.418-90.508
				C339.102,17.129,298.024,2.564,256,2.564c-42.024,0-83.102,14.564-115.666,41.01c-28.818,23.404-49.957,55.197-60.418,90.508
				c-8.488,1.733-16.243,5.842-22.421,11.874c-11.668,0.141-22.891,4.787-31.397,13.292c-8.506,8.506-13.152,19.73-13.293,31.398
				C4.651,198.997,0,210.22,0,222.252c0,12.034,4.651,23.257,12.804,31.607c0.14,11.668,4.787,22.891,13.292,31.397
				c8.402,8.403,19.524,13.102,31.379,13.275c4.305,4.207,9.379,7.475,14.929,9.662v122.299l-55.332,55.332l23.611,23.611
				l55.332-55.332h319.968l55.332,55.332l23.611-23.611l-55.332-55.332V308.194C445.145,306.007,450.218,302.739,454.524,298.532z
				 M99.276,272.285c-2.203,3.556-6.006,5.68-10.174,5.68c-4.168,0-7.972-2.124-10.175-5.681l-6.323-10.208l-11.689,2.747
				c-4.099,0.965-8.3-0.273-11.205-3.178c-2.945-2.945-4.132-7.136-3.176-11.209l2.745-11.688l-10.208-6.322
				c-3.556-2.202-5.679-6.005-5.679-10.173c0-4.167,2.124-7.972,5.68-10.174l10.206-6.323l-2.745-11.688
				c-0.956-4.073,0.232-8.263,3.175-11.207c2.945-2.945,7.134-4.134,11.209-3.177l11.687,2.745l6.322-10.206
				c2.203-3.556,6.006-5.68,10.175-5.68c4.169,0,7.973,2.123,10.174,5.679l6.322,10.207l11.687-2.745
				c4.075-0.953,8.264,0.232,11.209,3.177s4.133,7.136,3.177,11.208l-2.745,11.688l10.207,6.323c3.556,2.203,5.68,6.006,5.68,10.174
				s-2.123,7.972-5.679,10.174l-10.208,6.322l2.745,11.688c0.956,4.074-0.232,8.264-3.176,11.208
				c-2.904,2.904-7.1,4.144-11.208,3.177l-11.688-2.746L99.276,272.285z M105.797,420.713c0,0,0-112.518,0-112.518
				c5.551-2.187,10.624-5.455,14.929-9.663c5.697-0.083,11.218-1.221,16.355-3.303c10.627,15.951,11.651,40.212,11.735,125.484
				H105.797z M152.104,159.248c-8.507-8.505-19.728-13.151-31.397-13.291c-2.341-2.285-4.914-4.284-7.661-5.994
				c8.894-27.444,25.725-52.104,48.338-70.468c21.976-17.847,48.699-29.069,76.689-32.451c-2.95,22.574-11.176,47.193-23.599,69.881
				c-10.397,18.989-27.685,43.759-54.215,63.406C158.152,166.313,155.428,162.571,152.104,159.248z M333.094,317.404
				c-2.997,21.806-3.278,51.833-3.305,103.308H182.212c-0.027-51.475-0.308-81.503-3.306-103.308
				c-2.679-19.487-7.742-32.992-17.542-45.497c2.571-5.655,3.957-11.785,4.033-18.048c8.153-8.351,12.804-19.574,12.804-31.607
				c0-7.219-1.686-14.141-4.786-20.332c35.135-23.861,57.384-55.283,70.348-78.959c4.58-8.365,8.672-16.991,12.237-25.728
				c3.565,8.737,7.657,17.363,12.237,25.728c12.962,23.677,35.212,55.098,70.348,78.958c-3.101,6.192-4.786,13.114-4.786,20.333
				c0,12.034,4.651,23.257,12.804,31.607c0.076,6.263,1.461,12.393,4.033,18.048C340.836,284.413,335.773,297.917,333.094,317.404z
				 M351.74,170.33c-26.531-19.645-43.818-44.416-54.215-63.406c-12.422-22.688-20.649-47.308-23.599-69.881
				c27.99,3.381,54.714,14.603,76.689,32.451c22.615,18.365,39.445,43.025,48.337,70.468c-2.747,1.71-5.32,3.709-7.661,5.994
				c-11.668,0.141-22.891,4.787-31.397,13.292C356.571,162.571,353.847,166.313,351.74,170.33z M406.203,420.714h-43.019v-0.001
				c0.083-85.273,1.107-109.534,11.735-125.484c5.137,2.081,10.66,3.22,16.355,3.303c4.305,4.207,9.378,7.475,14.929,9.663V420.714z
				 M439.397,262.077l-6.323,10.208c-2.203,3.556-6.007,5.681-10.175,5.681s-7.972-2.123-10.174-5.68l-6.323-10.208l-11.688,2.746
				c-4.107,0.965-8.303-0.273-11.207-3.177c-2.945-2.945-4.133-7.136-3.177-11.209l2.745-11.688l-10.208-6.322
				c-3.555-2.203-5.679-6.006-5.679-10.174c0-4.167,2.124-7.972,5.68-10.174l10.207-6.323l-2.745-11.688
				c-0.956-4.073,0.232-8.263,3.176-11.207c2.945-2.945,7.135-4.134,11.211-3.177l11.687,2.745l6.322-10.207
				c2.203-3.555,6.006-5.679,10.174-5.679s7.973,2.123,10.175,5.68l6.322,10.206l11.687-2.746c4.076-0.956,8.264,0.232,11.209,3.177
				c2.945,2.945,4.133,7.136,3.177,11.208l-2.745,11.688l10.206,6.323c3.556,2.203,5.68,6.006,5.68,10.174
				s-2.123,7.972-5.679,10.174l-10.208,6.322l2.745,11.688c0.956,4.074-0.23,8.264-3.176,11.209
				c-2.904,2.903-7.106,4.141-11.206,3.177L439.397,262.077z"/>
                                                        <circle cx="422.901" cy="222.253" r="19.887" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </Grid>
                                    <Grid item xl={6} xs={10} spacing={0} textAlign="center"><h3>Vadde Sobhanadreeswara Rao, Katuru Rd, Nearby Landmark: Hanuman temple.. kadavakollu, Vuyyuru</h3></Grid>
                                </Grid>
                                <Grid container justifyContent="center" alignItems="center" >
                                    <Grid item xl={12} xs={12} spacing={0} textAlign="center"><a target="_blank" href="https://www.google.com/maps/place/16%C2%B023'48.6%22N+80%C2%B051'09.2%22E/@16.3968322,80.8524484,20.5z/data=!4m4!3m3!8m2!3d16.3968304!4d80.8525487?hl=en&entry=ttu"><Button variant="outlined">Directions</Button></a></Grid>
                                </Grid>

                            </CardContent>

                        </Card></div>
                </Grid>
                <Grid item xl={4} xs={3} spacing={0} textAlign="center"></Grid>
            </Grid>


        </>
    );
}

export default Invitaion;
